// COMPONENTS
import React from 'react'
import ContentContainer from 'layout/ContentContainer'
import Endcap from 'layout/Endcap'

// Landing page section component
const Section = ({ content, children }) => {
  const { id='section', inverted=false, endcap='none', title, subtitle } = content
  return (
    <ContentContainer id={id}>
      <ContentContainer id={`${id}-content`} type='section' inverted={inverted}>
        {title && <h1 className='pt-5'>{title}</h1>} 
        {subtitle && <h5>{subtitle}</h5>}
        {children}
      </ContentContainer>
      <ContentContainer
      id={`${id}-endcap`}
      type='sectionEndcap'
      inverted={inverted}>
        <Endcap type={endcap} inverted={inverted} />
      </ContentContainer>
    </ContentContainer>
  )
}

export default Section
