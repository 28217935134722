// COMPONENTS
import React, { createElement } from 'react'
// ICON SET
import * as Icons from 'react-icons/fa'

// Font Awesome Icons component
const Icon = ({ name, size='100px' }) => {
  let icon = null
  try{
    icon = createElement(Icons[name], {size})
  } catch (err) { console.err(err) }

  return (<>{icon ? icon : <></>}</>)
}

export default Icon