// COMPONENTS
import React, { useState } from 'react'
import MarkdownBody from 'elements/MarkdownBody'
import Section from 'layout/Section'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// LOGIC
import { handleEnterKey } from 'apis/Browser'
import { signUp } from 'apis/EmailSignup'
// ICONS
import Icon from 'elements/Icon'

// Signup component
const SignUp = ({ content }) => {
  const [email, setEmail] = useState(null)
  const [valid, setValid] = useState(false)
  const { section, section: { inverted }, title, subtitle, button, icon, list, bodyMd, footer } = content
  const doSignUp = () => signUp(valid, email, list)

  return (
  <Section content={section}>
    <Container fluid>
      <Row>
        <Col className='text-center pb-2' xs={12}>
          {title && <h1 className='pt-5'>{title}</h1>}
          {icon && <Icon name={icon} />}
          {subtitle && <h4>{subtitle}</h4>}
        </Col>
        <Col className='text-center pb-2' xs={12}>
          <MarkdownBody
            addClass='text-center'
            markdown={bodyMd}
            inverted={inverted}
          />
        </Col>
      </Row>
      <Form>
        <Form.Row>
          <Col className='text-center' xs={12} md={{ span: 8, offset: 2 }}>
            <InputGroup size='lg'>
              <Form.Control
                required
                type='email'
                placeholder='Enter your email'
                onKeyPress={e => handleEnterKey(e, doSignUp)}
                onChange={e => {
                  setEmail(e.target.value)
                  setValid(e.target.validity.valid)
                }}
              />
              <Form.Control.Feedback>OK!</Form.Control.Feedback>
              <InputGroup.Append>
                <Button variant={button.color} onClick={doSignUp}>
                  {button.text}
                </Button>
              </InputGroup.Append>
            </InputGroup>
            {footer && <Form.Text className='text-muted'>{footer}</Form.Text>}
          </Col>
        </Form.Row>
      </Form>
    </Container>
  </Section>
  )
}

export default SignUp
