import React from 'react'
import { Link } from 'gatsby'

import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Footer = ({ siteTitle }) => {
  return (
    // <footer className={`footer-long bg-dark`}>
    <Navbar as='footer'>
      <Row className='align-items-center p-3 no-gutters container-fluid'>
        <Col className='text-center text-muted small'>
          <Link to='/info'>
            &copy; {new Date().getFullYear()} VE&Gamma;I&Gamma;EV
          </Link>
          <br />
          <Link to='/info'>Made with love in Los Angeles</Link>
          <br />
        </Col>
      </Row>
    </Navbar>
    // </footer>
  )
}

export default Footer
