import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// STYLES
import { ThemeContext } from 'layout/ThemeProvider'
import Container from 'react-bootstrap/Container'
import Header from 'layout/Header'
import Footer from 'layout/Footer'
import 'styles/main.scss'
// HOOKS
import { useSiteMetadata } from 'hooks/useSiteMetadata'

  
const Layout = ({ children, header }) => {
  const { themeMode } = useContext(ThemeContext)
  const [scrollState, setScrollState] = useState('top')
  let listener = null

  // Change state if user scrolled from top of page
  useEffect(() => {
    listener = document.addEventListener('scroll', e => {
      var scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 75) {
        if (scrollState !== '888') {
          setScrollState('888')
        }
      } else {
        if (scrollState !== 'top') {
          setScrollState('top')
        }
      }
    })
    return () => {
      document.removeEventListener('scroll', listener)
    }  
  }, [scrollState])

  const { title: metaTitle } = useSiteMetadata()
  const siteTitle = header ? header.siteTitle : metaTitle
  // console.log({themeMode})
  return (
  <Container id='layout' className={`px-0 bg-${themeMode}`} fluid>
    <Header
      header={header}
      siteTitle={siteTitle}
      scrollState={scrollState}
    />
    {children}
    <Footer siteTitle={siteTitle} />
  </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
