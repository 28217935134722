import React, { useContext } from 'react'
import { ThemeContext } from 'layout/ThemeProvider'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { FiMenu, FiSun, FiMoon } from 'react-icons/fi'
import defaultMenu from 'content/menu.yml'

// Dropdown menu component
const Menu = ({ menu }) => {
  const { themeMode, setThemeMode } = useContext(ThemeContext)
  const { links } = menu ? menu : defaultMenu

  return (
    <Dropdown as={ButtonGroup} toggle alignRight>
      {/* DARK MODE TOGGLE */}
      <ToggleButton
        type='checkbox'
        variant={themeMode === 'light' ? 'dark' : 'light'}
        checked={themeMode === 'light' ? true : false}
        value='1'
        onChange={e =>
          setThemeMode(e.currentTarget.checked ? 'light' : 'dark')
        }>
        {themeMode === 'light' ? <FiSun /> : <FiMoon />}
      </ToggleButton>
      <Dropdown.Toggle split variant='primary' id='dropdown-split-basic'>
        <FiMenu />
      </Dropdown.Toggle>

      {/* MENU ITEMS */}
      <Dropdown.Menu>
        {links.map(link => (
          <Dropdown.Item key={link.title} href={link.url}>
            {link.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Menu
