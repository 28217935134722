import React from 'react'
import { Link } from 'gatsby'
import Menu from 'layout/Menu'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

// Header component
const Header = ({ header, siteTitle, scrollState }) => {
  return (
    <Navbar
      sticky='top'
      className={`p-3 no-gutters container-fluid ${
        scrollState !== 'top' ? 'fade-bg-in' : 'fade-bg-out'
      }`}>
      <Navbar.Brand>
        <Link to={`/${header ? header.siteRoot:''}`}>{ header ? header.siteTitle : siteTitle }</Link>
      </Navbar.Brand>
      <Nav className='ml-auto'>
        <Menu menu={header ? header.menu : null } />
      </Nav>
    </Navbar>
  )
}

export default Header
