import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { ThemeContext } from 'layout/ThemeProvider'

const ContentContainer = ({ id, type, inverted, children }) => {
  let { themeMode, invertedTheme } = useContext(ThemeContext)
  // inverted forces style inversion on component
  if (inverted) [themeMode, invertedTheme] = [invertedTheme, themeMode]

  const containerType = {
    default: 'col-12 p-0 m-0',
    body: `col-md-8 col-11 py-2 bg-${themeMode} text-${invertedTheme}`,
    // sidebar:      "col-md-2 px-2 py-2 my-2",
    secondary: `col-12 col-md-8 py-4 text-center bg-${themeMode} text-${invertedTheme}`,
    section: `col-12 px-4 py-5 text-center bg-${themeMode} text-${invertedTheme}`,
    sectionEndcap: `col-12 p-0 bg-${invertedTheme}`,
    standout: `col-12 py-4 my-4 text-center bg-${themeMode} text-${invertedTheme}`,
    title: `col-12 px-2 py-2 text-center bg-${themeMode} text-${invertedTheme}`,
  }

  return (
  <Container id={id} className='p-0' fluid>
    <Row className='justify-content-center' noGutters>
      <div className={`${containerType[type]}`}>{children}</div>
    </Row>
  </Container>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

ContentContainer.defaultProps = {
  type: 'default',
  inverted: false,
  id: 'content-container',
}

export default ContentContainer
